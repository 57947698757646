//
// Tab items
//
.tn-events-listing-page {
    .tn-event-listing-mode-tab-nav__list-item {
        color: $text-color;
    }
}

//
// Calendar View
//
#tn-events-calendar-view-month {
    .tn-events-calendar__event {
        color: $text-color;
    }
}

.tn-events-list-view__datepicker-container .tn-btn-datepicker__icon-container {
    color: $white;
}

//
// List View
//
.tn-event-listing__primary-views-container {
    .tn-event-listing__primary-view {
        background: $page-background;
    }
    .tn-prod-list-item__property--heading a,
    .tn-event-listing-view__results-heading {
        color: $headings-color;
    }
}

.tn-btn-datepicker__btn-container {
   .btn-primary {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
        [data-whatintent="mouse"] & {
            &:hover,
            &:active,
            &:focus, {
                @include c-btn--hover($violet, $white);
            }
        }
    }
    .tn-btn-datepicker__icon-container {
        color: $white;
    }
}

.tn-listing-results-calendar {

}
.fc-event {
    border-radius: 2px;
    font-size: 80%;
    color: $white;
    background: $brand-success;
    &:hover {
        color: $white;
        background: lighten($brand-success, 10%);
    }
}
