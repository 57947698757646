
.tn-event-detail__display-time {

}

.tn-event-detail__purchase-instructions a {
    @include o-link;
}


//
// SYOS Cart Controls
//
.tn-syos-mobile-cart-controls {
    .tn-syos-mobile-cart-controls__summary {
        padding: 5px 15px;
    }
    .tn-syos__btn-add-to-cart {
        background-color: $btn-primary-bg;
        color: $btn-primary-color;
        font-weight: $btn-font-weight;
        text-transform: none;
        @include ff-cond;
        @include font-semi-bold;
        font-size: 120%;
    }
}

//
// Modal
//

.tn-syos .modal {
    top: $header-height-mobile + 20px;
    @media (min-width: $header-full-menu) {
        top: $header-height-desktop + 20px;
    }
}

.tn-syos-mobile-popover .tn-syos-mobile-overlay__scroll-content-wrapper {
    margin-top: $header-height-mobile + 20px;
    @media (min-width: $header-full-menu) {
        margin-top: $header-height-desktop + 20px;
    }
}
