.tn-syos-seat-map__seat {

    // Row AA
    &[data-tn-seat-id="25858"] { transform: matrix(1, 0, 0, 1, 120, 130); } // AA 101
    &[data-tn-seat-id="25857"] { transform: matrix(1, 0, 0, 1, 130, 110); }
    &[data-tn-seat-id="25856"] { transform: matrix(1, 0, 0, 1, 140, 92); }
    &[data-tn-seat-id="25855"] { transform: matrix(1, 0, 0, 1, 150, 80); }
    &[data-tn-seat-id="25854"] { transform: matrix(1, 0, 0, 1, 160, 73); }
    &[data-tn-seat-id="25853"] { transform: matrix(1, 0, 0, 1, 170, 73); }
    &[data-tn-seat-id="25852"] { transform: matrix(1, 0, 0, 1, 180, 80); }
    &[data-tn-seat-id="25851"] { transform: matrix(1, 0, 0, 1, 190, 92); }
    &[data-tn-seat-id="25850"] { transform: matrix(1, 0, 0, 1, 200, 110); } 
    &[data-tn-seat-id="25849"] { transform: matrix(1, 0, 0, 1, 210, 130); } // AA 110

    // Row BB
    &[data-tn-seat-id="25787"] { transform: matrix(1, 0, 0, 1, -465, 190); } // BB 1
    &[data-tn-seat-id="25761"] { transform: matrix(1, 0, 0, 1, 580, 195); }
    &[data-tn-seat-id="25788"] { transform: matrix(1, 0, 0, 1, -480, 220); }
    &[data-tn-seat-id="25760"] { transform: matrix(1, 0, 0, 1, 595, 225); } // BB 4

    &[data-tn-seat-id="25778"] { transform: matrix(1, 0, 0, 1, 80, 140); } // BB 101
    &[data-tn-seat-id="25777"] { transform: matrix(1, 0, 0, 1, 90, 120); }
    &[data-tn-seat-id="25776"] { transform: matrix(1, 0, 0, 1, 100, 100); }
    &[data-tn-seat-id="25775"] { transform: matrix(1, 0, 0, 1, 110, 88); }
    &[data-tn-seat-id="25774"] { transform: matrix(1, 0, 0, 1, 120, 83); }
    &[data-tn-seat-id="25773"] { transform: matrix(1, 0, 0, 1, 130, 80); }
    &[data-tn-seat-id="25772"] { transform: matrix(1, 0, 0, 1, 140, 83); }
    &[data-tn-seat-id="25771"] { transform: matrix(1, 0, 0, 1, 150, 88); }
    &[data-tn-seat-id="25770"] { transform: matrix(1, 0, 0, 1, 160, 100); }
    &[data-tn-seat-id="25769"] { transform: matrix(1, 0, 0, 1, 170, 120); }
    &[data-tn-seat-id="25768"] { transform: matrix(1, 0, 0, 1, 180, 140); } // BB 111

    // Row A
    &[data-tn-seat-id="25687"] { transform: matrix(1, 0, 0, 1, 150, 150); }
    &[data-tn-seat-id="25688"] { transform: matrix(1, 0, 0, 1, 140, 130); }
    &[data-tn-seat-id="25689"] { transform: matrix(1, 0, 0, 1, 130, 110); }
    &[data-tn-seat-id="25690"] { transform: matrix(1, 0, 0, 1, 120, 100); }
    &[data-tn-seat-id="25691"] { transform: matrix(1, 0, 0, 1, 110, 90); }
    &[data-tn-seat-id="25692"] { transform: matrix(1, 0, 0, 1, 100, 90); }
    &[data-tn-seat-id="25693"] { transform: matrix(1, 0, 0, 1, 90, 90); }
    &[data-tn-seat-id="25694"] { transform: matrix(1, 0, 0, 1, 80, 90); }
    &[data-tn-seat-id="25695"] { transform: matrix(1, 0, 0, 1, 70, 100); }
    &[data-tn-seat-id="25696"] { transform: matrix(1, 0, 0, 1, 60, 110); }
    &[data-tn-seat-id="25697"] { transform: matrix(1, 0, 0, 1, 50, 130); }
    &[data-tn-seat-id="25698"] { transform: matrix(1, 0, 0, 1, 40, 150); }

    // Row B
    &[data-tn-seat-id="25596"] { transform: matrix(1, 0, 0, 1, 570, 300); }
    &[data-tn-seat-id="25597"] { transform: matrix(1, 0, 0, 1, 550, 270); }
    &[data-tn-seat-id="25598"] { transform: matrix(1, 0, 0, 1, 530, 240); }
    &[data-tn-seat-id="25599"] { transform: matrix(1, 0, 0, 1, 510, 210); }

    &[data-tn-seat-id="25606"] { transform: matrix(1, 0, 0, 1, 120, 160); }
    &[data-tn-seat-id="25607"] { transform: matrix(1, 0, 0, 1, 110, 140); }
    &[data-tn-seat-id="25608"] { transform: matrix(1, 0, 0, 1, 100, 120); }
    &[data-tn-seat-id="25609"] { transform: matrix(1, 0, 0, 1, 90, 110); }
    &[data-tn-seat-id="25610"] { transform: matrix(1, 0, 0, 1, 80, 100); }
    &[data-tn-seat-id="25611"] { transform: matrix(1, 0, 0, 1, 70, 100); }
    &[data-tn-seat-id="25612"] { transform: matrix(1, 0, 0, 1, 60, 100); }
    &[data-tn-seat-id="25613"] { transform: matrix(1, 0, 0, 1, 50, 100); }
    &[data-tn-seat-id="25614"] { transform: matrix(1, 0, 0, 1, 40, 100); }
    &[data-tn-seat-id="25615"] { transform: matrix(1, 0, 0, 1, 30, 110); }
    &[data-tn-seat-id="25616"] { transform: matrix(1, 0, 0, 1, 20, 120); }
    &[data-tn-seat-id="25617"] { transform: matrix(1, 0, 0, 1, 10, 140); }
    &[data-tn-seat-id="25618"] { transform: matrix(1, 0, 0, 1, 0, 160); }

    &[data-tn-seat-id="25625"] { transform: matrix(1, 0, 0, 1, -400, 210); }
    &[data-tn-seat-id="25626"] { transform: matrix(1, 0, 0, 1, -420, 240); }
    &[data-tn-seat-id="25627"] { transform: matrix(1, 0, 0, 1, -440, 270); }
    &[data-tn-seat-id="25628"] { transform: matrix(1, 0, 0, 1, -460, 300); }

    // Row C

    &[data-tn-seat-id="25515"] { transform: matrix(1, 0, 0, 1, 540, 310); }
    &[data-tn-seat-id="25516"] { transform: matrix(1, 0, 0, 1, 520, 280); }
    &[data-tn-seat-id="25517"] { transform: matrix(1, 0, 0, 1, 500, 250); }
    &[data-tn-seat-id="25518"] { transform: matrix(1, 0, 0, 1, 480, 220); }

    &[data-tn-seat-id="25525"] { transform: matrix(1, 0, 0, 1, 90, 170); }
    &[data-tn-seat-id="25526"] { transform: matrix(1, 0, 0, 1, 80, 150); }
    &[data-tn-seat-id="25527"] { transform: matrix(1, 0, 0, 1, 70, 130); }
    &[data-tn-seat-id="25528"] { transform: matrix(1, 0, 0, 1, 60, 120); }
    &[data-tn-seat-id="25529"] { transform: matrix(1, 0, 0, 1, 50, 110); }
    &[data-tn-seat-id="25530"] { transform: matrix(1, 0, 0, 1, 40, 110); }
    &[data-tn-seat-id="25531"] { transform: matrix(1, 0, 0, 1, 30, 110); }
    &[data-tn-seat-id="25532"] { transform: matrix(1, 0, 0, 1, 20, 110); }
    &[data-tn-seat-id="25533"] { transform: matrix(1, 0, 0, 1, 10, 110); }
    &[data-tn-seat-id="25534"] { transform: matrix(1, 0, 0, 1, 0, 110); }
    &[data-tn-seat-id="25535"] { transform: matrix(1, 0, 0, 1, -10, 120); }
    &[data-tn-seat-id="25536"] { transform: matrix(1, 0, 0, 1, -20, 130); }
    &[data-tn-seat-id="25537"] { transform: matrix(1, 0, 0, 1, -30, 150); }
    &[data-tn-seat-id="25538"] { transform: matrix(1, 0, 0, 1, -40, 170); }

    &[data-tn-seat-id="25544"] { transform: matrix(1, 0, 0, 1, -370, 220); }
    &[data-tn-seat-id="25545"] { transform: matrix(1, 0, 0, 1, -390, 250); }
    &[data-tn-seat-id="25546"] { transform: matrix(1, 0, 0, 1, -410, 280); }
    &[data-tn-seat-id="25547"] { transform: matrix(1, 0, 0, 1, -430, 310); }

    // Row D

    &[data-tn-seat-id="25434"] { transform: matrix(1, 0, 0, 1, 510, 320); }
    &[data-tn-seat-id="25435"] { transform: matrix(1, 0, 0, 1, 490, 290); }
    &[data-tn-seat-id="25436"] { transform: matrix(1, 0, 0, 1, 470, 260); }
    &[data-tn-seat-id="25437"] { transform: matrix(1, 0, 0, 1, 450, 230); }

    &[data-tn-seat-id="25443"] { transform: matrix(1, 0, 0, 1, 140, 180); }
    &[data-tn-seat-id="25444"] { transform: matrix(1, 0, 0, 1, 130, 160); }
    &[data-tn-seat-id="25445"] { transform: matrix(1, 0, 0, 1, 120, 140); }
    &[data-tn-seat-id="25446"] { transform: matrix(1, 0, 0, 1, 110, 130); }
    &[data-tn-seat-id="25447"] { transform: matrix(1, 0, 0, 1, 100, 120); }
    &[data-tn-seat-id="25448"] { transform: matrix(1, 0, 0, 1, 90, 110); }
    &[data-tn-seat-id="25449"] { transform: matrix(1, 0, 0, 1, 80, 110); }
    &[data-tn-seat-id="25450"] { transform: matrix(1, 0, 0, 1, 70, 110); }
    &[data-tn-seat-id="25451"] { transform: matrix(1, 0, 0, 1, 60, 110); }
    &[data-tn-seat-id="25452"] { transform: matrix(1, 0, 0, 1, 50, 110); }
    &[data-tn-seat-id="25453"] { transform: matrix(1, 0, 0, 1, 40, 120); }
    &[data-tn-seat-id="25454"] { transform: matrix(1, 0, 0, 1, 30, 130); }
    &[data-tn-seat-id="25455"] { transform: matrix(1, 0, 0, 1, 20, 140); }
    &[data-tn-seat-id="25456"] { transform: matrix(1, 0, 0, 1, 10, 160); }
    &[data-tn-seat-id="25457"] { transform: matrix(1, 0, 0, 1, 0, 180); }

    // Row E

    &[data-tn-seat-id="25353"] { transform: matrix(1, 0, 0, 1, 530, 300); }
    &[data-tn-seat-id="25354"] { transform: matrix(1, 0, 0, 1, 510, 270); }
    &[data-tn-seat-id="25355"] { transform: matrix(1, 0, 0, 1, 490, 240); }
    &[data-tn-seat-id="25356"] { transform: matrix(1, 0, 0, 1, 470, 210); }

    &[data-tn-seat-id="25363"] { transform: matrix(1, 0, 0, 1, 100, 170); }
    &[data-tn-seat-id="25364"] { transform: matrix(1, 0, 0, 1, 90, 150); }
    &[data-tn-seat-id="25365"] { transform: matrix(1, 0, 0, 1, 80, 140); }
    &[data-tn-seat-id="25366"] { transform: matrix(1, 0, 0, 1, 70, 130); }
    &[data-tn-seat-id="25367"] { transform: matrix(1, 0, 0, 1, 60, 120); }
    &[data-tn-seat-id="25368"] { transform: matrix(1, 0, 0, 1, 50, 110); }
    &[data-tn-seat-id="25369"] { transform: matrix(1, 0, 0, 1, 40, 110); }
    &[data-tn-seat-id="25370"] { transform: matrix(1, 0, 0, 1, 30, 110); }
    &[data-tn-seat-id="25371"] { transform: matrix(1, 0, 0, 1, 20, 110); }
    &[data-tn-seat-id="25372"] { transform: matrix(1, 0, 0, 1, 10, 120); }
    &[data-tn-seat-id="25373"] { transform: matrix(1, 0, 0, 1, 0, 130); }
    &[data-tn-seat-id="25374"] { transform: matrix(1, 0, 0, 1, -10, 140); }
    &[data-tn-seat-id="25375"] { transform: matrix(1, 0, 0, 1, -20, 150); }
    &[data-tn-seat-id="25376"] { transform: matrix(1, 0, 0, 1, -30, 170); }

    &[data-tn-seat-id="25382"] { transform: matrix(1, 0, 0, 1, -340, 210); }
    &[data-tn-seat-id="25383"] { transform: matrix(1, 0, 0, 1, -360, 240); }
    &[data-tn-seat-id="25384"] { transform: matrix(1, 0, 0, 1, -380, 270); }
    &[data-tn-seat-id="25385"] { transform: matrix(1, 0, 0, 1, -400, 300); }

    // Row F

    &[data-tn-seat-id="25272"] { transform: matrix(1, 0, 0, 1, 500, 310); }
    &[data-tn-seat-id="25273"] { transform: matrix(1, 0, 0, 1, 480, 280); }
    &[data-tn-seat-id="25274"] { transform: matrix(1, 0, 0, 1, 460, 250); }
    &[data-tn-seat-id="25275"] { transform: matrix(1, 0, 0, 1, 440, 220); }

    &[data-tn-seat-id="25281"] { transform: matrix(1, 0, 0, 1, 140, 180); }
    &[data-tn-seat-id="25282"] { transform: matrix(1, 0, 0, 1, 130, 160); }
    &[data-tn-seat-id="25283"] { transform: matrix(1, 0, 0, 1, 120, 150); }
    &[data-tn-seat-id="25284"] { transform: matrix(1, 0, 0, 1, 110, 140); }
    &[data-tn-seat-id="25285"] { transform: matrix(1, 0, 0, 1, 100, 130); }
    &[data-tn-seat-id="25286"] { transform: matrix(1, 0, 0, 1, 90, 120); }
    &[data-tn-seat-id="25287"] { transform: matrix(1, 0, 0, 1, 80, 120); }
    &[data-tn-seat-id="25288"] { transform: matrix(1, 0, 0, 1, 70, 120); }
    &[data-tn-seat-id="25289"] { transform: matrix(1, 0, 0, 1, 60, 120); }
    &[data-tn-seat-id="25290"] { transform: matrix(1, 0, 0, 1, 50, 120); }
    &[data-tn-seat-id="25291"] { transform: matrix(1, 0, 0, 1, 40, 130); }
    &[data-tn-seat-id="25292"] { transform: matrix(1, 0, 0, 1, 30, 140); }
    &[data-tn-seat-id="25293"] { transform: matrix(1, 0, 0, 1, 20, 150); }
    &[data-tn-seat-id="25294"] { transform: matrix(1, 0, 0, 1, 10, 160); }
    &[data-tn-seat-id="25295"] { transform: matrix(1, 0, 0, 1, 0, 180); }

    &[data-tn-seat-id="25301"] { transform: matrix(1, 0, 0, 1, -310, 220); }
    &[data-tn-seat-id="25302"] { transform: matrix(1, 0, 0, 1, -330, 250); }
    &[data-tn-seat-id="25303"] { transform: matrix(1, 0, 0, 1, -350, 280); }
    &[data-tn-seat-id="25304"] { transform: matrix(1, 0, 0, 1, -370, 310); }

    // Row G

    &[data-tn-seat-id="25191"] { transform: matrix(1, 0, 0, 1, 520, 290); }
    &[data-tn-seat-id="25192"] { transform: matrix(1, 0, 0, 1, 500, 260); }
    &[data-tn-seat-id="25193"] { transform: matrix(1, 0, 0, 1, 480, 230); }
    &[data-tn-seat-id="25194"] { transform: matrix(1, 0, 0, 1, 460, 200); }

    &[data-tn-seat-id="25201"] { transform: matrix(1, 0, 0, 1, 100, 170); }
    &[data-tn-seat-id="25202"] { transform: matrix(1, 0, 0, 1, 90, 160); }
    &[data-tn-seat-id="25203"] { transform: matrix(1, 0, 0, 1, 80, 150); }
    &[data-tn-seat-id="25204"] { transform: matrix(1, 0, 0, 1, 70, 140); }
    &[data-tn-seat-id="25205"] { transform: matrix(1, 0, 0, 1, 60, 130); }
    &[data-tn-seat-id="25206"] { transform: matrix(1, 0, 0, 1, 50, 130); }
    &[data-tn-seat-id="25207"] { transform: matrix(1, 0, 0, 1, 40, 130); }
    &[data-tn-seat-id="25208"] { transform: matrix(1, 0, 0, 1, 30, 130); }
    &[data-tn-seat-id="25209"] { transform: matrix(1, 0, 0, 1, 20, 130); }
    &[data-tn-seat-id="25210"] { transform: matrix(1, 0, 0, 1, 10, 130); }
    &[data-tn-seat-id="25211"] { transform: matrix(1, 0, 0, 1, 0, 140); }
    &[data-tn-seat-id="25212"] { transform: matrix(1, 0, 0, 1, -10, 150); }
    &[data-tn-seat-id="25213"] { transform: matrix(1, 0, 0, 1, -20, 160); }
    &[data-tn-seat-id="25214"] { transform: matrix(1, 0, 0, 1, -30, 170); }

    &[data-tn-seat-id="25220"] { transform: matrix(1, 0, 0, 1, -320, 200); }
    &[data-tn-seat-id="25221"] { transform: matrix(1, 0, 0, 1, -340, 230); }
    &[data-tn-seat-id="25222"] { transform: matrix(1, 0, 0, 1, -360, 260); }
    &[data-tn-seat-id="25223"] { transform: matrix(1, 0, 0, 1, -380, 290); }

    // Row H

    &[data-tn-seat-id="25110"] { transform: matrix(1, 0, 0, 1, 490, 300); }
    &[data-tn-seat-id="25111"] { transform: matrix(1, 0, 0, 1, 470, 270); }
    &[data-tn-seat-id="25112"] { transform: matrix(1, 0, 0, 1, 450, 240); }
    &[data-tn-seat-id="25113"] { transform: matrix(1, 0, 0, 1, 430, 210); }

    &[data-tn-seat-id="25119"] { transform: matrix(1, 0, 0, 1, 140, 180); }
    &[data-tn-seat-id="25120"] { transform: matrix(1, 0, 0, 1, 130, 170); }
    &[data-tn-seat-id="25121"] { transform: matrix(1, 0, 0, 1, 120, 160); }
    &[data-tn-seat-id="25122"] { transform: matrix(1, 0, 0, 1, 110, 150); }
    &[data-tn-seat-id="25123"] { transform: matrix(1, 0, 0, 1, 100, 140); }
    &[data-tn-seat-id="25124"] { transform: matrix(1, 0, 0, 1, 90, 140); }
    &[data-tn-seat-id="25125"] { transform: matrix(1, 0, 0, 1, 80, 140); }
    &[data-tn-seat-id="25126"] { transform: matrix(1, 0, 0, 1, 70, 140); }
    &[data-tn-seat-id="25127"] { transform: matrix(1, 0, 0, 1, 60, 140); }
    &[data-tn-seat-id="25128"] { transform: matrix(1, 0, 0, 1, 50, 140); }
    &[data-tn-seat-id="25129"] { transform: matrix(1, 0, 0, 1, 40, 140); }
    &[data-tn-seat-id="25130"] { transform: matrix(1, 0, 0, 1, 30, 150); }
    &[data-tn-seat-id="25131"] { transform: matrix(1, 0, 0, 1, 20, 160); }
    &[data-tn-seat-id="25132"] { transform: matrix(1, 0, 0, 1, 10, 170); }
    &[data-tn-seat-id="25133"] { transform: matrix(1, 0, 0, 1, 0, 180); }

    &[data-tn-seat-id="25139"] { transform: matrix(1, 0, 0, 1, -290, 210); }
    &[data-tn-seat-id="25140"] { transform: matrix(1, 0, 0, 1, -310, 240); }
    &[data-tn-seat-id="25141"] { transform: matrix(1, 0, 0, 1, -330, 270); }
    &[data-tn-seat-id="25142"] { transform: matrix(1, 0, 0, 1, -350, 300); }

    // Row I

    &[data-tn-seat-id="25029"] { transform: matrix(1, 0, 0, 1, 510, 280); }
    &[data-tn-seat-id="25030"] { transform: matrix(1, 0, 0, 1, 490, 250); }
    &[data-tn-seat-id="25031"] { transform: matrix(1, 0, 0, 1, 470, 220); }
    &[data-tn-seat-id="25032"] { transform: matrix(1, 0, 0, 1, 450, 190); }

    &[data-tn-seat-id="25039"] { transform: matrix(1, 0, 0, 1, 100, 180); }
    &[data-tn-seat-id="25040"] { transform: matrix(1, 0, 0, 1, 90, 170); }
    &[data-tn-seat-id="25041"] { transform: matrix(1, 0, 0, 1, 80, 160); }
    &[data-tn-seat-id="25042"] { transform: matrix(1, 0, 0, 1, 70, 150); }
    &[data-tn-seat-id="25043"] { transform: matrix(1, 0, 0, 1, 60, 150); }
    &[data-tn-seat-id="25044"] { transform: matrix(1, 0, 0, 1, 50, 150); }
    &[data-tn-seat-id="25045"] { transform: matrix(1, 0, 0, 1, 40, 150); }
    &[data-tn-seat-id="25046"] { transform: matrix(1, 0, 0, 1, 30, 150); }
    &[data-tn-seat-id="25047"] { transform: matrix(1, 0, 0, 1, 20, 150); }
    &[data-tn-seat-id="25048"] { transform: matrix(1, 0, 0, 1, 10, 150); }
    &[data-tn-seat-id="25049"] { transform: matrix(1, 0, 0, 1, 0, 150); }
    &[data-tn-seat-id="25050"] { transform: matrix(1, 0, 0, 1, -10, 160); }
    &[data-tn-seat-id="25051"] { transform: matrix(1, 0, 0, 1, -20, 170); }
    &[data-tn-seat-id="25052"] { transform: matrix(1, 0, 0, 1, -30, 180); }

    // Row J

    &[data-tn-seat-id="24948"] { transform: matrix(1, 0, 0, 1, 530, 260); }
    &[data-tn-seat-id="24949"] { transform: matrix(1, 0, 0, 1, 510, 230); }
    &[data-tn-seat-id="24950"] { transform: matrix(1, 0, 0, 1, 490, 200); }
    &[data-tn-seat-id="24951"] { transform: matrix(1, 0, 0, 1, 470, 170); }

    &[data-tn-seat-id="24957"] { transform: matrix(1, 0, 0, 1, 140, 190); }
    &[data-tn-seat-id="24958"] { transform: matrix(1, 0, 0, 1, 130, 180); }
    &[data-tn-seat-id="24959"] { transform: matrix(1, 0, 0, 1, 120, 170); }
    &[data-tn-seat-id="24960"] { transform: matrix(1, 0, 0, 1, 110, 160); }
    &[data-tn-seat-id="24961"] { transform: matrix(1, 0, 0, 1, 100, 160); }
    &[data-tn-seat-id="24962"] { transform: matrix(1, 0, 0, 1, 90, 160); }
    &[data-tn-seat-id="24963"] { transform: matrix(1, 0, 0, 1, 80, 160); }
    &[data-tn-seat-id="24964"] { transform: matrix(1, 0, 0, 1, 70, 160); }
    &[data-tn-seat-id="24965"] { transform: matrix(1, 0, 0, 1, 60, 160); }
    &[data-tn-seat-id="24966"] { transform: matrix(1, 0, 0, 1, 50, 160); }
    &[data-tn-seat-id="24967"] { transform: matrix(1, 0, 0, 1, 40, 160); }
    &[data-tn-seat-id="24968"] { transform: matrix(1, 0, 0, 1, 30, 160); }
    &[data-tn-seat-id="24969"] { transform: matrix(1, 0, 0, 1, 20, 170); }
    &[data-tn-seat-id="24970"] { transform: matrix(1, 0, 0, 1, 10, 180); }
    &[data-tn-seat-id="24971"] { transform: matrix(1, 0, 0, 1, 0, 190); }

    &[data-tn-seat-id="24977"] { transform: matrix(1, 0, 0, 1, -290, 200); }
    &[data-tn-seat-id="24978"] { transform: matrix(1, 0, 0, 1, -310, 230); }
    &[data-tn-seat-id="24979"] { transform: matrix(1, 0, 0, 1, -330, 260); }
    &[data-tn-seat-id="24980"] { transform: matrix(1, 0, 0, 1, -350, 290); }

    // Row K

    &[data-tn-seat-id="24869"] { transform: matrix(1, 0, 0, 1, 460, 210); }
    &[data-tn-seat-id="24870"] { transform: matrix(1, 0, 0, 1, 440, 180); }

    &[data-tn-seat-id="24877"] { transform: matrix(1, 0, 0, 1, 100, 190); }
    &[data-tn-seat-id="24878"] { transform: matrix(1, 0, 0, 1, 90, 180); }
    &[data-tn-seat-id="24879"] { transform: matrix(1, 0, 0, 1, 80, 170); }
    &[data-tn-seat-id="24880"] { transform: matrix(1, 0, 0, 1, 70, 170); }
    &[data-tn-seat-id="24881"] { transform: matrix(1, 0, 0, 1, 60, 170); }
    &[data-tn-seat-id="24882"] { transform: matrix(1, 0, 0, 1, 50, 170); }
    &[data-tn-seat-id="24883"] { transform: matrix(1, 0, 0, 1, 40, 170); }
    &[data-tn-seat-id="24884"] { transform: matrix(1, 0, 0, 1, 30, 170); }
    &[data-tn-seat-id="24885"] { transform: matrix(1, 0, 0, 1, 20, 170); }
    &[data-tn-seat-id="24886"] { transform: matrix(1, 0, 0, 1, 10, 170); }
    &[data-tn-seat-id="24887"] { transform: matrix(1, 0, 0, 1, 0, 170); }
    &[data-tn-seat-id="24888"] { transform: matrix(1, 0, 0, 1, -10, 170); }
    &[data-tn-seat-id="24889"] { transform: matrix(1, 0, 0, 1, -20, 180); }
    &[data-tn-seat-id="24890"] { transform: matrix(1, 0, 0, 1, -30, 190); }

    &[data-tn-seat-id="24896"] { transform: matrix(1, 0, 0, 1, -260, 220); }
    &[data-tn-seat-id="24897"] { transform: matrix(1, 0, 0, 1, -280, 250); }

    // Row L

    &[data-tn-seat-id="24877"] { transform: matrix(1, 0, 0, 1, 100, 190); }
    &[data-tn-seat-id="24878"] { transform: matrix(1, 0, 0, 1, 90, 180); }
    &[data-tn-seat-id="24879"] { transform: matrix(1, 0, 0, 1, 80, 170); }
    &[data-tn-seat-id="24880"] { transform: matrix(1, 0, 0, 1, 70, 170); }
    &[data-tn-seat-id="24881"] { transform: matrix(1, 0, 0, 1, 60, 170); }
    &[data-tn-seat-id="24882"] { transform: matrix(1, 0, 0, 1, 50, 170); }
    &[data-tn-seat-id="24883"] { transform: matrix(1, 0, 0, 1, 40, 170); }
    &[data-tn-seat-id="24884"] { transform: matrix(1, 0, 0, 1, 30, 170); }
    &[data-tn-seat-id="24885"] { transform: matrix(1, 0, 0, 1, 20, 170); }
    &[data-tn-seat-id="24886"] { transform: matrix(1, 0, 0, 1, 10, 170); }
    &[data-tn-seat-id="24887"] { transform: matrix(1, 0, 0, 1, 0, 170); }
    &[data-tn-seat-id="24888"] { transform: matrix(1, 0, 0, 1, -10, 170); }
    &[data-tn-seat-id="24889"] { transform: matrix(1, 0, 0, 1, -20, 180); }
    &[data-tn-seat-id="24890"] { transform: matrix(1, 0, 0, 1, -30, 190); }

    // Row M

    &[data-tn-seat-id="24795"] { transform: matrix(1, 0, 0, 1, 140, 200); }
    &[data-tn-seat-id="24796"] { transform: matrix(1, 0, 0, 1, 130, 190); }
    &[data-tn-seat-id="24797"] { transform: matrix(1, 0, 0, 1, 120, 180); }
    &[data-tn-seat-id="24798"] { transform: matrix(1, 0, 0, 1, 110, 180); }
    &[data-tn-seat-id="24799"] { transform: matrix(1, 0, 0, 1, 100, 180); }
    &[data-tn-seat-id="24800"] { transform: matrix(1, 0, 0, 1, 90, 180); }
    &[data-tn-seat-id="24801"] { transform: matrix(1, 0, 0, 1, 80, 180); }
    &[data-tn-seat-id="24802"] { transform: matrix(1, 0, 0, 1, 70, 180); }
    &[data-tn-seat-id="24803"] { transform: matrix(1, 0, 0, 1, 60, 180); }
    &[data-tn-seat-id="24804"] { transform: matrix(1, 0, 0, 1, 50, 180); }
    &[data-tn-seat-id="24805"] { transform: matrix(1, 0, 0, 1, 40, 180); }
    &[data-tn-seat-id="24806"] { transform: matrix(1, 0, 0, 1, 30, 180); }
    &[data-tn-seat-id="24807"] { transform: matrix(1, 0, 0, 1, 20, 180); }
    &[data-tn-seat-id="24808"] { transform: matrix(1, 0, 0, 1, 10, 190); }
    &[data-tn-seat-id="24809"] { transform: matrix(1, 0, 0, 1, 0, 200); }

    // Row N

    &[data-tn-seat-id="24716"] { transform: matrix(1, 0, 0, 1, 90, 180); }
    &[data-tn-seat-id="24717"] { transform: matrix(1, 0, 0, 1, 80, 160); }
    &[data-tn-seat-id="24718"] { transform: matrix(1, 0, 0, 1, 70, 140); }
    &[data-tn-seat-id="24719"] { transform: matrix(1, 0, 0, 1, 60, 120); }
    &[data-tn-seat-id="24720"] { transform: matrix(1, 0, 0, 1, 50, 110); }
    &[data-tn-seat-id="24721"] { transform: matrix(1, 0, 0, 1, 40, 100); }
    &[data-tn-seat-id="24722"] { transform: matrix(1, 0, 0, 1, 30, 100); }
    &[data-tn-seat-id="24723"] { transform: matrix(1, 0, 0, 1, 20, 110); }
    &[data-tn-seat-id="24724"] { transform: matrix(1, 0, 0, 1, 10, 120); }
    &[data-tn-seat-id="24725"] { transform: matrix(1, 0, 0, 1, 0, 140); }
    &[data-tn-seat-id="24726"] { transform: matrix(1, 0, 0, 1, -10, 160); }
    &[data-tn-seat-id="24727"] { transform: matrix(1, 0, 0, 1, -20, 180); }

    // Row O

    &[data-tn-seat-id="24634"] { transform: matrix(1, 0, 0, 1, 80, 170); }
    &[data-tn-seat-id="24635"] { transform: matrix(1, 0, 0, 1, 60, 140); }
    &[data-tn-seat-id="24636"] { transform: matrix(1, 0, 0, 1, 40, 110); }
    &[data-tn-seat-id="24637"] { transform: matrix(1, 0, 0, 1, 30, 90); }
    &[data-tn-seat-id="24638"] { transform: matrix(1, 0, 0, 1, 20, 80); }
    &[data-tn-seat-id="24639"] { transform: matrix(1, 0, 0, 1, 10, 70); }
    &[data-tn-seat-id="24640"] { transform: matrix(1, 0, 0, 1, 0, 60); }
    &[data-tn-seat-id="24641"] { transform: matrix(1, 0, 0, 1, -10, 60); }
    &[data-tn-seat-id="24642"] { transform: matrix(1, 0, 0, 1, -20, 60); }
    &[data-tn-seat-id="24643"] { transform: matrix(1, 0, 0, 1, -30, 70); }
    &[data-tn-seat-id="24644"] { transform: matrix(1, 0, 0, 1, -40, 80); }
    &[data-tn-seat-id="24645"] { transform: matrix(1, 0, 0, 1, -50, 90); }
    &[data-tn-seat-id="24646"] { transform: matrix(1, 0, 0, 1, -60, 110); }
    &[data-tn-seat-id="24647"] { transform: matrix(1, 0, 0, 1, -80, 140); }
    &[data-tn-seat-id="24648"] { transform: matrix(1, 0, 0, 1, -100, 170); }


    // Row P

    &[data-tn-seat-id="24552"] { transform: matrix(1, 0, 0, 1, 120, 180); }
    &[data-tn-seat-id="24553"] { transform: matrix(1, 0, 0, 1, 100, 150); }
    &[data-tn-seat-id="24554"] { transform: matrix(1, 0, 0, 1, 80, 120); }
    &[data-tn-seat-id="24555"] { transform: matrix(1, 0, 0, 1, 70, 100); }
    &[data-tn-seat-id="24556"] { transform: matrix(1, 0, 0, 1, 60, 90); }
    &[data-tn-seat-id="24557"] { transform: matrix(1, 0, 0, 1, 50, 80); }
    &[data-tn-seat-id="24558"] { transform: matrix(1, 0, 0, 1, 40, 70); }
    &[data-tn-seat-id="24559"] { transform: matrix(1, 0, 0, 1, 30, 60); }
    &[data-tn-seat-id="24560"] { transform: matrix(1, 0, 0, 1, 20, 60); }
    &[data-tn-seat-id="24561"] { transform: matrix(1, 0, 0, 1, 10, 60); }
    &[data-tn-seat-id="24562"] { transform: matrix(1, 0, 0, 1, 0, 70); }
    &[data-tn-seat-id="24563"] { transform: matrix(1, 0, 0, 1, -10, 90); }
    &[data-tn-seat-id="24564"] { transform: matrix(1, 0, 0, 1, -20, 100); }
    &[data-tn-seat-id="24565"] { transform: matrix(1, 0, 0, 1, -30, 120); }
    &[data-tn-seat-id="24566"] { transform: matrix(1, 0, 0, 1, -50, 150); }
    &[data-tn-seat-id="24567"] { transform: matrix(1, 0, 0, 1, -70, 180); }

}
