.vfs__link {
    margin-top: $gutter-s;
    position: relative;
    display: block;
    &::after {
        content: '';
        display: block;
        padding-bottom: 66.67%;
    }
}

.vfs__seat-img {
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($black, 0.5);
}


.vfs__expand {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba($black, 0.5);
    color: $white;
    padding: 0.25em;
    font-size: 90%;
    > i {
        margin-left: 6px;
    }
}
