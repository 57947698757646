.tn-ticketing-mode-change__anchor,
.tn-additional-events__continue-shopping-link {
    @include o-link;
}
.tn-additional-events__continue-shopping-link {
    font-size: 120%;
}
.tn-events-detail-page {
    //
    // Move additional events container below select your seat
    //

    .tn-event-detail {
        @media screen and (min-width: $screen-sm) {
            flex-wrap: wrap;
            .tn-event-detail__additional-events-container {
                order: 3;
                flex-basis: 100%;
                margin: 24px 0;
                .tn-additional-events {
                    max-width: 400px;
                }
            }
        }
        .tn-event-detail__title {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            line-height: $headings-line-height;
            color: $headings-color;
            font-size: $font-size-h2;
        }
        .tn-event-detail__main-container {
            margin-bottom: 0;
            width: 100%;
        }
        .tn-event-detail__location {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            color: $headings-color;
            font-size: $font-size-h4;
            margin-top: 12px;
            .js__venue-image {
                margin: 0 0 0 0;
            }
            .o-link {
                font-size: $font-size-base;
            }
            @media screen and (min-width: $screen-xs) {
                .js__venue-image {
                    display: inline-block;
                    margin: 0 0 0 12px;
                }
            }
        }
        //
        // Select another event
        //
        #tn-additional-events-select {
            transition: all 0.3s;
            background-color: transparent;
            border-radius: $border-radius-small 0 0 $border-radius-small;
            border: 1px solid rgba(0, 0, 0, 0.25);
            padding-left: 6px;
            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
            &:focus {
                @include tab-focus;
            }
        }
        #tn-additional-events-btn-submit {
            @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
            box-shadow: none;
            border-radius: 0 4px 4px 0;
            text-decoration: none;
            line-height: 1.5;
            padding: 5px 10px;
            height: 53px;
        }
    }
    .tn-syos-screen-button:not([disabled]) {
        @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    }
}
