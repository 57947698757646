body {
    background-color: black(10);
}

.c-main-content-wrap {
    background-color: black(10);
    @media (min-width: $screen-xs-max) {
        padding: 0 $gutter-s $gutter-s;
    }
}
.page-wrap {
    background-color: $white;
    padding-top: 100px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    @media (min-width: $header-full-menu) {
        padding-top: 150px;
    }
}
.content-wrap {
    padding: 0 $gutter-s;
    width: 100%;
    margin: 0 auto;
    @media (min-width: $screen-xs-max) {
        padding: 0 $gutter-m;
        max-width: 1440px;
    }
}
