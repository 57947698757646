.tn-syos-seat-map__seat {
    // Right

    &[data-tn-seat-id="29585"] { transform: matrix(1, 0, 0, 1, 580, 350); }
    &[data-tn-seat-id="29504"] { transform: matrix(1, 0, 0, 1, 550, 350); }
    &[data-tn-seat-id="29423"] { transform: matrix(1, 0, 0, 1, 530, 350); }
    &[data-tn-seat-id="29342"] { transform: matrix(1, 0, 0, 1, 510, 350); }
    &[data-tn-seat-id="29261"] { transform: matrix(1, 0, 0, 1, 500, 340); }
    &[data-tn-seat-id="29180"] { transform: matrix(1, 0, 0, 1, 490, 330); }
    &[data-tn-seat-id="29099"] { transform: matrix(1, 0, 0, 1, 480, 320); }
    &[data-tn-seat-id="29018"] { transform: matrix(1, 0, 0, 1, 480, 310); }
    &[data-tn-seat-id="28937"] { transform: matrix(1, 0, 0, 1, 480, 300); }
    &[data-tn-seat-id="28856"] { transform: matrix(1, 0, 0, 1, 480, 290); }
    &[data-tn-seat-id="28775"] { transform: matrix(1, 0, 0, 1, 480, 280); }
    &[data-tn-seat-id="28694"] { transform: matrix(1, 0, 0, 1, 490, 270); }
    &[data-tn-seat-id="28613"] { transform: matrix(1, 0, 0, 1, 500, 260); }
    &[data-tn-seat-id="28532"] { transform: matrix(1, 0, 0, 1, 510, 250); }
    &[data-tn-seat-id="28451"] { transform: matrix(1, 0, 0, 1, 530, 240); }
    &[data-tn-seat-id="28370"] { transform: matrix(1, 0, 0, 1, 560, 240); }
    &[data-tn-seat-id="28289"] { transform: matrix(1, 0, 0, 1, 600, 240); }

    // Left

    &[data-tn-seat-id="29622"] { transform: matrix(1, 0, 0, 1, -430, 350); }
    &[data-tn-seat-id="29541"] { transform: matrix(1, 0, 0, 1, -400, 350); }
    &[data-tn-seat-id="29460"] { transform: matrix(1, 0, 0, 1, -380, 350); }
    &[data-tn-seat-id="29379"] { transform: matrix(1, 0, 0, 1, -360, 340); }
    &[data-tn-seat-id="29298"] { transform: matrix(1, 0, 0, 1, -340, 330); }
    &[data-tn-seat-id="29217"] { transform: matrix(1, 0, 0, 1, -330, 320); }
    &[data-tn-seat-id="29136"] { transform: matrix(1, 0, 0, 1, -320, 310); }
    &[data-tn-seat-id="29055"] { transform: matrix(1, 0, 0, 1, -320, 300); }
    &[data-tn-seat-id="28974"] { transform: matrix(1, 0, 0, 1, -320, 290); }
    &[data-tn-seat-id="28893"] { transform: matrix(1, 0, 0, 1, -320, 280); }
    &[data-tn-seat-id="28812"] { transform: matrix(1, 0, 0, 1, -320, 270); }
    &[data-tn-seat-id="28731"] { transform: matrix(1, 0, 0, 1, -320, 260); }
    &[data-tn-seat-id="28650"] { transform: matrix(1, 0, 0, 1, -330, 250); }
    &[data-tn-seat-id="28569"] { transform: matrix(1, 0, 0, 1, -340, 240); }
    &[data-tn-seat-id="28488"] { transform: matrix(1, 0, 0, 1, -360, 230); }
    &[data-tn-seat-id="28407"] { transform: matrix(1, 0, 0, 1, -390, 230); }
    &[data-tn-seat-id="28326"] { transform: matrix(1, 0, 0, 1, -420, 230); }

    // Row T

    &[data-tn-seat-id="28214"] { transform: matrix(1, 0, 0, 1, 310, 50); }
    &[data-tn-seat-id="28215"] { transform: matrix(1, 0, 0, 1, 290, 10); }
    &[data-tn-seat-id="28216"] { transform: matrix(1, 0, 0, 1, 270, -30); }
    &[data-tn-seat-id="28217"] { transform: matrix(1, 0, 0, 1, 250, -70); }
    &[data-tn-seat-id="28218"] { transform: matrix(1, 0, 0, 1, 240, -100); }
    &[data-tn-seat-id="28219"] { transform: matrix(1, 0, 0, 1, 230, -130); }
    &[data-tn-seat-id="28220"] { transform: matrix(1, 0, 0, 1, 220, -160); }
    &[data-tn-seat-id="28221"] { transform: matrix(1, 0, 0, 1, 210, -180); }
    &[data-tn-seat-id="28222"] { transform: matrix(1, 0, 0, 1, 200, -200); }
    &[data-tn-seat-id="28223"] { transform: matrix(1, 0, 0, 1, 190, -210); }
    &[data-tn-seat-id="28224"] { transform: matrix(1, 0, 0, 1, 180, -220); }
    &[data-tn-seat-id="28225"] { transform: matrix(1, 0, 0, 1, 170, -230); }
    &[data-tn-seat-id="28226"] { transform: matrix(1, 0, 0, 1, 160, -230); }
    &[data-tn-seat-id="28227"] { transform: matrix(1, 0, 0, 1, 150, -220); }
    &[data-tn-seat-id="28228"] { transform: matrix(1, 0, 0, 1, 140, -210); }
    &[data-tn-seat-id="28229"] { transform: matrix(1, 0, 0, 1, 130, -200); }
    &[data-tn-seat-id="28230"] { transform: matrix(1, 0, 0, 1, 120, -180); }
    &[data-tn-seat-id="28231"] { transform: matrix(1, 0, 0, 1, 110, -160); }
    &[data-tn-seat-id="28232"] { transform: matrix(1, 0, 0, 1, 100, -130); }
    &[data-tn-seat-id="28233"] { transform: matrix(1, 0, 0, 1, 80, -100); }
    &[data-tn-seat-id="28234"] { transform: matrix(1, 0, 0, 1, 60, -70); }
    &[data-tn-seat-id="28235"] { transform: matrix(1, 0, 0, 1, 40, -30); }
    &[data-tn-seat-id="28236"] { transform: matrix(1, 0, 0, 1, 20, 10); }
    &[data-tn-seat-id="28237"] { transform: matrix(1, 0, 0, 1, 0, 50); }

    // Row U

    &[data-tn-seat-id="28134"] { transform: matrix(1, 0, 0, 1, 270, -20); }
    &[data-tn-seat-id="28135"] { transform: matrix(1, 0, 0, 1, 250, -60); }
    &[data-tn-seat-id="28136"] { transform: matrix(1, 0, 0, 1, 230, -100); }
    &[data-tn-seat-id="28137"] { transform: matrix(1, 0, 0, 1, 220, -130); }
    &[data-tn-seat-id="28138"] { transform: matrix(1, 0, 0, 1, 210, -160); }
    &[data-tn-seat-id="28139"] { transform: matrix(1, 0, 0, 1, 200, -190); }
    &[data-tn-seat-id="28140"] { transform: matrix(1, 0, 0, 1, 190, -210); }
    &[data-tn-seat-id="28141"] { transform: matrix(1, 0, 0, 1, 180, -230); }
    &[data-tn-seat-id="28142"] { transform: matrix(1, 0, 0, 1, 170, -240); }
    &[data-tn-seat-id="28143"] { transform: matrix(1, 0, 0, 1, 160, -250); }
    &[data-tn-seat-id="28144"] { transform: matrix(1, 0, 0, 1, 150, -260); }
    &[data-tn-seat-id="28145"] { transform: matrix(1, 0, 0, 1, 140, -260); }
    &[data-tn-seat-id="28146"] { transform: matrix(1, 0, 0, 1, 130, -260); }
    &[data-tn-seat-id="28147"] { transform: matrix(1, 0, 0, 1, 120, -250); }
    &[data-tn-seat-id="28148"] { transform: matrix(1, 0, 0, 1, 110, -240); }
    &[data-tn-seat-id="28149"] { transform: matrix(1, 0, 0, 1, 100, -230); }
    &[data-tn-seat-id="28150"] { transform: matrix(1, 0, 0, 1, 90, -210); }
    &[data-tn-seat-id="28151"] { transform: matrix(1, 0, 0, 1, 80, -190); }
    &[data-tn-seat-id="28152"] { transform: matrix(1, 0, 0, 1, 70, -160); }
    &[data-tn-seat-id="28153"] { transform: matrix(1, 0, 0, 1, 60, -130); }
    &[data-tn-seat-id="28154"] { transform: matrix(1, 0, 0, 1, 40, -100); }
    &[data-tn-seat-id="28155"] { transform: matrix(1, 0, 0, 1, 20, -60); }
    &[data-tn-seat-id="28156"] { transform: matrix(1, 0, 0, 1, 0, -20); }

    // Row V


    &[data-tn-seat-id="28053"] { transform: matrix(1, 0, 0, 1, 290, -100); }
    &[data-tn-seat-id="28054"] { transform: matrix(1, 0, 0, 1, 270, -140); }
    &[data-tn-seat-id="28055"] { transform: matrix(1, 0, 0, 1, 260, -170); }
    &[data-tn-seat-id="28056"] { transform: matrix(1, 0, 0, 1, 250, -200); }
    &[data-tn-seat-id="28057"] { transform: matrix(1, 0, 0, 1, 240, -220); }
    &[data-tn-seat-id="28058"] { transform: matrix(1, 0, 0, 1, 230, -240); }
    &[data-tn-seat-id="28059"] { transform: matrix(1, 0, 0, 1, 220, -260); }
    &[data-tn-seat-id="28060"] { transform: matrix(1, 0, 0, 1, 210, -270); }
    &[data-tn-seat-id="28061"] { transform: matrix(1, 0, 0, 1, 200, -280); }
    &[data-tn-seat-id="28062"] { transform: matrix(1, 0, 0, 1, 190, -290); }
    &[data-tn-seat-id="28063"] { transform: matrix(1, 0, 0, 1, 180, -290); }
    &[data-tn-seat-id="28064"] { transform: matrix(1, 0, 0, 1, 170, -290); }
    &[data-tn-seat-id="28065"] { transform: matrix(1, 0, 0, 1, 160, -290); }
    &[data-tn-seat-id="28066"] { transform: matrix(1, 0, 0, 1, 150, -280); }
    &[data-tn-seat-id="28067"] { transform: matrix(1, 0, 0, 1, 140, -270); }
    &[data-tn-seat-id="28068"] { transform: matrix(1, 0, 0, 1, 130, -260); }
    &[data-tn-seat-id="28069"] { transform: matrix(1, 0, 0, 1, 120, -240); }
    &[data-tn-seat-id="28070"] { transform: matrix(1, 0, 0, 1, 110, -220); }
    &[data-tn-seat-id="28071"] { transform: matrix(1, 0, 0, 1, 100, -200); }
    &[data-tn-seat-id="28072"] { transform: matrix(1, 0, 0, 1, 80, -170); }
    &[data-tn-seat-id="28073"] { transform: matrix(1, 0, 0, 1, 60, -140); }
    &[data-tn-seat-id="28074"] { transform: matrix(1, 0, 0, 1, 40, -100); }

    // Row W
    &[data-tn-seat-id="27973"] { transform: matrix(1, 0, 0, 1, 240, -170); }
    &[data-tn-seat-id="27974"] { transform: matrix(1, 0, 0, 1, 230, -200); }
    &[data-tn-seat-id="27975"] { transform: matrix(1, 0, 0, 1, 220, -230); }
    &[data-tn-seat-id="27976"] { transform: matrix(1, 0, 0, 1, 210, -250); }
    &[data-tn-seat-id="27977"] { transform: matrix(1, 0, 0, 1, 200, -270); }
    &[data-tn-seat-id="27978"] { transform: matrix(1, 0, 0, 1, 190, -290); }
    &[data-tn-seat-id="27979"] { transform: matrix(1, 0, 0, 1, 180, -300); }
    &[data-tn-seat-id="27980"] { transform: matrix(1, 0, 0, 1, 170, -310); }
    &[data-tn-seat-id="27981"] { transform: matrix(1, 0, 0, 1, 160, -320); }
    &[data-tn-seat-id="27982"] { transform: matrix(1, 0, 0, 1, 150, -320); }
    &[data-tn-seat-id="27983"] { transform: matrix(1, 0, 0, 1, 140, -320); }
    &[data-tn-seat-id="27984"] { transform: matrix(1, 0, 0, 1, 130, -320); }
    &[data-tn-seat-id="27985"] { transform: matrix(1, 0, 0, 1, 120, -320); }
    &[data-tn-seat-id="27986"] { transform: matrix(1, 0, 0, 1, 110, -310); }
    &[data-tn-seat-id="27987"] { transform: matrix(1, 0, 0, 1, 100, -300); }
    &[data-tn-seat-id="27988"] { transform: matrix(1, 0, 0, 1, 90, -290); }
    &[data-tn-seat-id="27989"] { transform: matrix(1, 0, 0, 1, 80, -270); }
    &[data-tn-seat-id="27990"] { transform: matrix(1, 0, 0, 1, 70, -250); }
    &[data-tn-seat-id="27991"] { transform: matrix(1, 0, 0, 1, 60, -230); }
    &[data-tn-seat-id="27992"] { transform: matrix(1, 0, 0, 1, 50, -200); }
    &[data-tn-seat-id="27993"] { transform: matrix(1, 0, 0, 1, 40, -170); }


    // Row X

    &[data-tn-seat-id="27893"] { transform: matrix(1, 0, 0, 1, 190, -230); }
    &[data-tn-seat-id="27894"] { transform: matrix(1, 0, 0, 1, 180, -260); }
    &[data-tn-seat-id="27895"] { transform: matrix(1, 0, 0, 1, 170, -290); }
    &[data-tn-seat-id="27896"] { transform: matrix(1, 0, 0, 1, 160, -310); }
    &[data-tn-seat-id="27897"] { transform: matrix(1, 0, 0, 1, 150, -320); }
    &[data-tn-seat-id="27898"] { transform: matrix(1, 0, 0, 1, 140, -330); }
    &[data-tn-seat-id="27906"] { transform: matrix(1, 0, 0, 1, 130, -330); }
    &[data-tn-seat-id="27907"] { transform: matrix(1, 0, 0, 1, 120, -320); }
    &[data-tn-seat-id="27908"] { transform: matrix(1, 0, 0, 1, 110, -300); }
    &[data-tn-seat-id="27909"] { transform: matrix(1, 0, 0, 1, 100, -280); }
    &[data-tn-seat-id="27910"] { transform: matrix(1, 0, 0, 1, 90, -260); }
    &[data-tn-seat-id="27911"] { transform: matrix(1, 0, 0, 1, 80, -230); }





}
