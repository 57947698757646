.tn-cart-details-page, .tn-payment-page {
    // Alert Upsell
    .tn-heading-info {
        button {
            @include btn-link;
            @include o-link;
        }
    }
    //
    // Cart Items
    //
    .tn-cart__section-items {
        .tn-performance-title {
            color: $headings-color;
            font-weight: $headings-font-weight;
        }
    }
    //
    // Cart Totals
    //
    .tn-cart-totals__line-item {
        color: $gray-darker;
    }
    .tn-cart-totals__line-item--primary {
        color: $text-color;
    }
    .tn-cart-totals__value--total {
        font-weight: $headings-font-weight;
    }
    //
    // Cart Buttons
    //
    .tn-cart-buttons {
        margin-top: 30px;
        .tn-cart-buttons__secondary-action {
            @include o-link;
            font-size: 120%;
        }
    }
    .tn-edit-billing-link, .tn-edit-delivery-link {
        @include o-link;
    }
    .tn-payment-submit-component {
        margin-top: $padding-base-vertical;
    }
}
