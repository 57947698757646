/* ==========================================================================
   # GLOBAL HEADER STYLES
   ========================================================================== */

$header-height-mobile: 55px;
$header-height-desktop: 65px;
$header-full-menu: 1100px;
$header-full-width: 1280px;

.c-global-header {
    width: 100%;
    @include font-bold;
    height: $header-height-mobile;
    position: fixed;
    top: 0;
    z-index: 10; // Low enough that it doesn't overlap close section button on SYOS
    min-width: 320px;
    transition: all 200ms ease-in-out 0ms;
    background: $darkBlue;
    -webkit-box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.05);
    @media (min-width: $header-full-menu) {
        z-index: 302; // Above seatmap but below modals
    }

    .admin-bar & {
        top: 32px;
    }

    .state--nav-open & {
        @media (max-width: $header-full-menu) {
            height: calc((var(--vh, 1vh) * 100));
        }
    }

    @media (min-width: $header-full-menu) {
        height: $header-height-desktop;
    }

    &__logo {
        background-image: url('../img/la-jolla-logo.svg');
        width: 80px;
        height: 80px;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        z-index: 600;
        position: absolute;
        left: $gutter-s;
        top: 0;
        -webkit-box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.05);
        box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.05);

        @media (min-width: $screen-xs-max) {
            left: $gutter-m;
        }

        @media (min-width: $header-full-menu) {
            width: 120px;
            height: 120px;
        }
    }

    .c-content-wrap {
        height: 100%;
        padding: 0 20px;
    }
}

// IE 11 fixes

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .c-global-navigation {
        @media (max-width: $header-full-menu) {
            height: 100vh; // Set the navigation height in IE 11 which cannot use variables.

            // Hide scrollbar on IE 11
            -ms-overflow-style: none;
            overflow: auto;
        }
    }
}

// Edge fixes

@supports (-ms-ime-align: auto) {
    .c-global-navigation {
        @media (max-width: $header-full-menu) {

            // Hide scrollbar on Edge
            -ms-overflow-style: none;
            overflow: auto;
        }
    }
}

/*  ==========================================================================
    # Navigation styles
    ========================================================================== */


.c-global-navigation {
    color: $white;
    text-align: center;
    @include ff-cond;
    @include font-med;
    font-size: 130%;
    padding-top: 100px;

    @media (max-width: $header-full-menu) {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        transition: $global-transition;

        .state--nav-open & {
            opacity: 1;
            max-height: 100em;
            visibility: visible;
        }

    }

    @media (min-width: $header-full-menu) {
        line-height: 1;
        display: inline-block;
        width: 740px;
        vertical-align: middle;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        @include translate(-50%, -50%);
        padding-top: 0;
    }

    &__item {
        color: $white;
        display: block;
        &:focus {
            text-decoration: none;
            color: rgba($white, 0.75);
        }
        [data-whatintent="mouse"] & {
            &:hover {
                text-decoration: none;
                color: $white;
            }
        }
        @media (min-width: $header-full-menu) {
            padding:  22px 15px;
            display: inline-block;
            position: relative;

            &::after {
                display: none;
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                @include translate(-50%, 0);
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid black(10);

                .current & {
                    display: block;
                }
            }

            [data-whatintent="mouse"] & {
                &:hover {
                    &::after {
                        display: block;
                    }
                }
            }
        }
    }
}

body.state--nav-open {
    overflow: hidden;
    height: 100%;
}

.c-account-links {
    color: $white;
    display: inline-block;
    @include ff-reg;
    @include font-bold;
    position: absolute;
    right: $gutter-xs;
    top: 13px;

    @media (min-width: 500px) {
        right: $gutter-s;
    }

    @media (min-width: $header-full-width) {
        right: $gutter-m;
    }

    @media (min-width: $header-full-menu) {
        position: absolute;
        top: 50%;
        @include translate(0, -50%);
    }

    &__item {
        display: inline-block;
        margin-left: 5px;
        font-size: 120%;
        color: $white;
        &:hover, &:active, &:focus {
            text-decoration: none;
            color: $white;
        }

        @media (min-width: 768px) {
            margin-left: 15px;
            font-size: 80%;
        }

        .far {
            margin-right: 5px;
            color: $mint
        }

        span {
            @media (max-width: $header-full-menu) {
                @include sr-only();
            }
        }
    }
}

/*  ==========================================================================
    # Nav trigger styles
    ========================================================================== */

button[type="button"].c-menu-trigger {
    padding: 0;
    display: inline-block;
    cursor: pointer;
    border: 0;
    z-index: 260;
    white-space: nowrap;
    vertical-align: top;
    color: $white;
    @include font-semi-bold;
    text-align: center;
    top: 13px;
    position: absolute;
    left: 50%;
    @include translate(-50%, 0);
    background: transparent;

    @media (min-width: $header-full-menu) {
        display: none;
    }

    &:hover {
        background: transparent;
    }
}

.c-menu-trigger__label {
    display: block;
    font-size: 70%;
    line-height: 1;
}


/* Accessibility Skip link button
   ========================================================================== */

.c-btn--skip-link {
    @include button-variant($mint, $darkBlue, $mint);
    margin: 0 auto;
    position: absolute;
    z-index: 2000;
    left: 0;
    right: 0;
    top: 0.5em;
    width: 20em;
    opacity: 1;

    [data-whatintent="mouse"] & {
        &:hover,
        &:active,
        &:focus {
            // @include c-btn--hover($mint, $darkBlue);
        }
    }
}

.c-btn--skip-link:not(:focus):not(:hover) {
    -webkit-transform: translateY(-4em);
    -ms-transform: translateY(-4em);
    transform: translateY(-4em);
    opacity: 0;
}
