#tn-account-login-forgot-login-link {
    @include o-link;
    font-size: 120%;
}

.btn-info.tn-register-button {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    [data-whatintent="mouse"] & {
        &:hover,
        &:active,
        &:focus, {
            @include c-btn--hover($violet, $white);
        }
    }
}

.tn-account-tab-navigation {
    .nav-tabs li {
        color: $text-color;
        font-weight: 900;
    }
}

.tn-account-update-password-page {
    margin-top: $header-height-mobile;
    @media screen and (min-width: $screen-md) {
        margin-top: $header-height-desktop;
    }
}

.tn-subnav-component .tn-login-link.tn-logged-in .tn-logout-link {
    @media screen and (max-width: $screen-xs) {
        display: inline-block;

        &::before {
            display: none;
        }
    }
}
