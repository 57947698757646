.tn-flex-performance-group__btn-toggle-productions {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  padding-top: 15px;
  padding-bottom: 15px;
  [data-whatintent="mouse"] & {
    &:hover,
    &:active,
    &:focus {
        @include c-btn--hover($orange, $darkBlue);
    }
  }
}

.tn-flex-performance-group .tn-toggle-btn span.tn-toggle-btn__icon {
    color: inherit;
}


.c-cross-sell {
    background-color: black(10);
    padding: 1em;

    @media (min-width: $screen-sm) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__message {
        margin: 0 !important;

        p {
            margin: 0;

            & + p {
                margin-top: 12px;
            }
        }

        & + .c-cross-sell__cta {
            margin-top: 12px;

            @media (min-width: $screen-sm) {
                margin-top: 0;
            }
        }

        @media (min-width: $screen-sm) {
            width: 75%;
            padding-right: 1em;
        }
    }


    &__cta {
        margin-bottom: 0 !important;

        @media (min-width: $screen-sm) {
            width: 25%;
            text-align: center;

            .btn {
                max-width: 200px;
                margin: 0 auto;
            }
        }
    }

    &__title {
        margin-top: 0;
    }

    select {
        margin-bottom: 12px;
    }
}
