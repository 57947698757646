.c-footer {
    background-color: $darkBlue;
    color: $white;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

.c-footer__container--image {
    background-color: $violet;
    border-top: 10px solid $darkBlue;
    .c-footer__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: $gutter-m;
        > a {
            display: flex;
            justify-content: center;
        }
        @media (min-width: $screen-xs-max) {
            padding-top: $gutter-s;
            flex-direction: row;
            justify-content: flex-start;
        }
        @media (min-width: $screen-sm-max) {
            padding-top: $gutter-m;
            padding-bottom: $gutter-s;
        }
    }
    img {
        max-width: 60%;
        @media (min-width: $screen-xs-max) {
            max-width: 250px;
        }
        @media (min-width: $screen-sm-max) {
            max-width: 300px;
        }
    }
}

.c-footer__container--upper {
    background-color: $violet;
    .c-footer__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        @include fonts;
        @include font-bold;
        padding: $gutter-s 0 $gutter-m 0;
        @media (min-width: $screen-xs-max) {
            flex-direction: row;
            justify-content: flex-start;
            text-align: left;
        }
        .c-footer__phone {
            display: inline-block;
            @include o-link;
        }
    }
    .c-footer__contact, .c-footer__nav {
        margin: 0 auto;
        @media (min-width: $screen-xs-max) {
            margin: 0 $gutter-m 0 0;
            max-width: 300px;
        }
        @media (min-width: 960px) {
            max-width: 400px;
        }
        a, p {
            margin-bottom: $gutter-s;
        }
        a {
            color: $white;
            &:focus {
                text-decoration: none;
                color: rgba($white, 0.75);
            }
            [data-whatintent="mouse"] & {
                &:hover {
                    text-decoration: none;
                    color: $white;
                }
            }
        }
    }
    .c-footer__social, .c-footer__social-icons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: $gutter-s;
        @media (min-width: $screen-xs-max) {
            flex-direction: row;
            padding-top: 0;
            margin-left: auto;
            justify-content: flex-start;
        }
    }
    .c-footer__social-icons {
        flex-direction: row;
        a {
            background: $white;
            min-width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            margin: 0 0.5em;
            @media (min-width: $screen-xs-max) {
                margin: 0 0 0 0.5em;
            }
        }
    }
    .c-footer__social {
        padding-top: $gutter-m;
        @media (min-width: $screen-xs-max) {
            padding-top: 0;
        }
    }
    .c-footer__social-dash {
        margin: 0 0 0 0.5em;
        display: none;
        @media (min-width: $screen-xs-max) {
            display: inline;
        }
    }
}

.c-footer__container--lower {
    min-height: 80px;
    padding:  $gutter-s 0;
    @media (min-width: $screen-xs-max) {
        padding:  $gutter-s 0 $gutter-m 0;
    }
    .c-footer__content {
        @include ff-reg;
        @include font-light;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
        font-size: 90%;
        text-align: center;
        @media (min-width: $screen-xs-max) {
            flex-direction: row;
            text-align: left;
        }
    }
    .c-footer__copyright {
        margin: 0 0 $gutter-s;
        @media (min-width: $screen-xs-max) {
            margin: 0 $gutter-s 0 0;
        }
    }
}
