//
// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  transition: $global-transition;
  @include ff-cond;
  @include font-semi-bold;
  font-size: 120%;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $border-radius-base);
  @include user-select(none);
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }
  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }
  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    pointer-events: none; // Future-proof disabling of clicks
    @include opacity(.65);
    @include box-shadow(none);
  }
}

@mixin c-btn--hover($bg-color: $primary, $txt-color: $white) {
    background-color: darken($bg-color, 5%);
    color: $txt-color;
}


// Alternate buttons
// --------------------------------------------------
.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
  [data-whatintent="mouse"] & {
      &:hover,
      &:active,
      &:focus, {
          @include c-btn--hover($violet, $white);
      }
  }
}

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  [data-whatintent="mouse"] & {
    &:hover,
    &:active,
    &:focus {
        @include c-btn--hover($orange, $darkBlue);
    }
  }
}

// Success appears as green
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Link buttons
// -------------------------
// Make a button look and behave like a link
@mixin btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;
  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

.btn-link {
  @include btn-link;
}

// Button Sizes
// --------------------------------------------------
.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}

.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}

// Block button
// --------------------------------------------------
.btn-block {
  display: block;
  width: 100%;
  max-width: 400px;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

//
// Links
//

///* ========================================================================
//   #UNDERLINE
//   ======================================================================== */

// Creates a responsive underline for text that stays aligned visually across multiple lines.

@mixin text-underline-crop($background) {
    text-shadow:  .03em 0 $background,
                  -.03em 0 $background,
                  0 .03em $background,
                  0 -.03em $background,
                  .06em 0 $background,
                  -.06em 0 $background,
                  .09em 0 $background,
                  -.09em 0 $background,
                  .12em 0 $background,
                  -.12em 0 $background,
                  .15em 0 $background,
                  -.15em 0 $background;
}

@mixin text-background($color-bg, $color-text, $size) {
    background-image: linear-gradient($color-text, $color-text);
    background-size: $size $size;
    background-repeat: repeat-x;
    background-position:  0% 100%;
}

@mixin text-selection($selection) {
    &::selection {
        @include text-underline-crop($selection);

        background: $selection;
    }

    &::-moz-selection {
        @include text-underline-crop($selection);

        background: $selection;
    }
}

@mixin link-underline($background: transparent, $text: currentColor, $border: currentColor, $size: 1px, $selection: cyan){
    @include text-underline-crop($background);
    @include text-background($background, $border, $size);
    @include text-selection($selection);

    color: $text;
    text-decoration: none;

    *,
    *:after,
    &:after,
    *:before,
    &:before {
        text-shadow: none;
    }

    &:visited {
        color: $text;
    }
}


// Link Mixin
@mixin o-link {
  @include ff-cond;
  @include font-med;
  cursor: pointer;
  position: relative;
  @include link-underline(transparent, currentColor, $orange, 2px); // Sets a responsive underline
  padding: 5px 0;
  @include transition(background-size, 150ms);
  > i {
    margin-left: 6px;
  }
  [data-whatintent="mouse"] & {
      &:hover {
          @include link-underline(transparent, currentColor, $orange, 4px); // Sets a responsive underline
      }
  }
}

.o-link {
  @include o-link;
}
