body.tn-syos--viewing-seats {
        .tn-syos {
            z-index: 10000;
        }

        .tn-syos-seat-container, .tn-syos-seat-container-viewport__content-wrapper, .tn-syos-seat-container-viewport {
            height: 85vh !important;

            @media (min-width: $screen-sm-min) {
                min-height: 890px !important;
            }
        }

        .tn-syos-seat-map__non-seats {
            visibility: hidden;
        }
        .tn-syos-zoom-control {
            z-index: 40;
        }

//     .tn-syos__layout-container, .tn-syos-screen-container, .tn-syos-seat-container, .tn-syos-seat-container-viewport {
//         min-height: 85vh !important;
//         @media (min-width: $screen-sm-min) {
//             min-height: 840px !important;
//         }
//     }
//     .tn-syos-seat-container-viewport__content-wrapper {
//         top: 40px;
//         bottom: 40px;
//         width: 1200px;
//         max-width: 98%;
//         margin: -2% auto 0;
//     }
//     .tn-syos-seat-container-viewport {
//     }
    .tn-syos-seat-map {
        position: relative;
        z-index: 20;
        visibility: visible;
    }
    .tn-syos-seat-map__svg {
        max-height: 100%;
        background-color: transparent;
    	z-index: 600;
    	position: relative;
        overflow: visible !important;
    }
}

.state--venue-baker-baum {
    .tn-syos-seat-map {
        &::before {
            content: '';
            position: absolute;
            top: -42% !important;
            left: -38% !important;
            width: 181% !important;
            height: 235% !important;
            background-size: 66% !important;
            background: url('https://la-jolla-music-society-assets.s3.amazonaws.com/tnew-template/qa/img/background.png');
            background-position: top center;
            background-repeat: no-repeat;
            z-index: 10;
        }
    }
}

.tn-syos {
    .tn-syos__btn-add-to-cart,
    .tn-syos-mobile-cart-controls__summary-subtotal {
        font-size: 1em;
        @media (min-width: $screen-sm-min) {
            font-size: 1.25em;
        }
    }
}
