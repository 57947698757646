.tn-syos-seat-map__seat {
    // Box A

    &[data-tn-seat-id="34576"] { transform: matrix(1, 0, 0, 1, 20, -10); }
    &[data-tn-seat-id="34496"] { transform: matrix(1, 0, 0, 1, 30, 0); }
    &[data-tn-seat-id="34416"] { transform: matrix(1, 0, 0, 1, 40, 10); }
    &[data-tn-seat-id="34336"] { transform: matrix(1, 0, 0, 1, 50, 20); }
    &[data-tn-seat-id="34335"] { transform: matrix(1, 0, 0, 1, 30, 10); }
    &[data-tn-seat-id="34415"] { transform: matrix(1, 0, 0, 1, 20, 0); }
    &[data-tn-seat-id="34575"] { transform: matrix(1, 0, 0, 1, 0, -20); }
    &[data-tn-seat-id="34495"] { transform: matrix(1, 0, 0, 1, 10, -10); }

    // Box B

    &[data-tn-seat-id="34015"] { transform: matrix(1, 0, 0, 1, 50, 70); }
    &[data-tn-seat-id="33935"] { transform: matrix(1, 0, 0, 1, 60, 80); }
    &[data-tn-seat-id="33855"] { transform: matrix(1, 0, 0, 1, 60, 90); }
    &[data-tn-seat-id="33775"] { transform: matrix(1, 0, 0, 1, 60, 100); }
    &[data-tn-seat-id="34016"] { transform: matrix(1, 0, 0, 1, 70, 70); }
    &[data-tn-seat-id="33936"] { transform: matrix(1, 0, 0, 1, 70, 80); }
    &[data-tn-seat-id="33856"] { transform: matrix(1, 0, 0, 1, 70, 90); }
    &[data-tn-seat-id="33776"] { transform: matrix(1, 0, 0, 1, 70, 100); }



    // Box C

    &[data-tn-seat-id="33455"] { transform: matrix(1, 0, 0, 1, 30, 120); }
    &[data-tn-seat-id="33375"] { transform: matrix(1, 0, 0, 1, 10, 120); }
    &[data-tn-seat-id="33295"] { transform: matrix(1, 0, 0, 1, -20, 120); }
    &[data-tn-seat-id="33215"] { transform: matrix(1, 0, 0, 1, -50, 130); }
    &[data-tn-seat-id="33135"] { transform: matrix(1, 0, 0, 1, -90, 140); }
    &[data-tn-seat-id="33055"] { transform: matrix(1, 0, 0, 1, -130, 150); }
    &[data-tn-seat-id="33456"] { transform: matrix(1, 0, 0, 1, 90, 120); }
    &[data-tn-seat-id="33376"] { transform: matrix(1, 0, 0, 1, 70, 120); }
    &[data-tn-seat-id="33296"] { transform: matrix(1, 0, 0, 1, 40, 140); }
    &[data-tn-seat-id="33216"] { transform: matrix(1, 0, 0, 1, 20, 140); }
    &[data-tn-seat-id="33136"] { transform: matrix(1, 0, 0, 1, -20, 160); }
    &[data-tn-seat-id="33056"] { transform: matrix(1, 0, 0, 1, -50, 170); }
    &[data-tn-seat-id="32976"] { transform: matrix(1, 0, 0, 1, -100, 200); }
    &[data-tn-seat-id="32896"] { transform: matrix(1, 0, 0, 1, -140, 210); }

    // Box D

    &[data-tn-seat-id="32871"] { transform: matrix(1, 0, 0, 1, 350, 170); }
    &[data-tn-seat-id="33032"] { transform: matrix(1, 0, 0, 1, 330, 110); }
    &[data-tn-seat-id="33112"] { transform: matrix(1, 0, 0, 1, 290, 90); }
    &[data-tn-seat-id="33192"] { transform: matrix(1, 0, 0, 1, 250, 70); }
    &[data-tn-seat-id="33272"] { transform: matrix(1, 0, 0, 1, 210, 50); }
    &[data-tn-seat-id="33352"] { transform: matrix(1, 0, 0, 1, 180, 40); }
    &[data-tn-seat-id="33432"] { transform: matrix(1, 0, 0, 1, 160, 30); }
    &[data-tn-seat-id="32951"] { transform: matrix(1, 0, 0, 1, 310, 140); }
    &[data-tn-seat-id="33031"] { transform: matrix(1, 0, 0, 1, 270, 130); }
    &[data-tn-seat-id="33111"] { transform: matrix(1, 0, 0, 1, 230, 110); }
    &[data-tn-seat-id="33191"] { transform: matrix(1, 0, 0, 1, 200, 90); }
    &[data-tn-seat-id="33271"] { transform: matrix(1, 0, 0, 1, 170, 70); }
    &[data-tn-seat-id="33351"] { transform: matrix(1, 0, 0, 1, 150, 60); }
    &[data-tn-seat-id="33431"] { transform: matrix(1, 0, 0, 1, 130, 50); }

    // Box E

    &[data-tn-seat-id="34152"] { transform: matrix(1, 0, 0, 1, 110, 0); }
    &[data-tn-seat-id="34072"] { transform: matrix(1, 0, 0, 1, 100, 10); }
    &[data-tn-seat-id="33992"] { transform: matrix(1, 0, 0, 1, 90, 10); }
    &[data-tn-seat-id="33912"] { transform: matrix(1, 0, 0, 1, 90, 20); }
    &[data-tn-seat-id="33832"] { transform: matrix(1, 0, 0, 1, 90, 20); }
    &[data-tn-seat-id="33752"] { transform: matrix(1, 0, 0, 1, 90, 30); }
    &[data-tn-seat-id="33672"] { transform: matrix(1, 0, 0, 1, 100, 10); }
    &[data-tn-seat-id="33592"] { transform: matrix(1, 0, 0, 1, 110, 20); }
    &[data-tn-seat-id="33751"] { transform: matrix(1, 0, 0, 1, 90, 0); }
    &[data-tn-seat-id="33831"] { transform: matrix(1, 0, 0, 1, 80, 0); }
    &[data-tn-seat-id="33911"] { transform: matrix(1, 0, 0, 1, 80, 0); }
    &[data-tn-seat-id="33991"] { transform: matrix(1, 0, 0, 1, 90, 0); }

    // Box F
    &[data-tn-seat-id="34312"] { transform: matrix(1, 0, 0, 1, 120, 10); }
    &[data-tn-seat-id="34552"] { transform: matrix(1, 0, 0, 1, 170, -20); }
    &[data-tn-seat-id="34472"] { transform: matrix(1, 0, 0, 1, 160, -10); }
    &[data-tn-seat-id="34392"] { transform: matrix(1, 0, 0, 1, 140, 0); }
    &[data-tn-seat-id="34551"] { transform: matrix(1, 0, 0, 1, 180, 0); }
    &[data-tn-seat-id="34471"] { transform: matrix(1, 0, 0, 1, 160, 10); }
    &[data-tn-seat-id="34391"] { transform: matrix(1, 0, 0, 1, 140, 20); }
    &[data-tn-seat-id="34311"] { transform: matrix(1, 0, 0, 1, 120, 30); }

}
